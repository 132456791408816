import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListPractitionersQuery,
  ListUsersQuery,
  GetLoggedInUserQuery,
  CreatePractitionerMutation,
  UpdatePractitionerMutation,
  DeactivateUserMutation,
  CreatePublicDocumentUploadMutation,
  AdminCreatePublicDocumentUploadMutation
} from 'src/API';
// import {
//   listPractitioners,
//   listUsers,
//   getLoggedInUser
// } from 'src/graphql/queries';
import {
  customListPractitioners,
  customLoggedInUser,
  customListUsers,
  customListUsersOnLoad
} from './queries';
import {
  createPractitioner,
  updatePractitioner,
  deactivateUser,
  createPublicDocumentUpload,
  adminCreatePublicDocumentUpload
} from 'src/graphql/mutations';
// import { createPayment, voidPayment } from 'src/graphql/mutations';

export const listUsersAPI = async () => {
  try {
    const users = await callGraphQL<ListUsersQuery>(customListUsers, {
      variables: {}
    });

    return users;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listUsersOnLoadAPI = async () => {
  try {
    const users = await callGraphQL<ListUsersQuery>(customListUsersOnLoad, {
      variables: {}
    });

    return users;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getLoggedInUserAPI = async () => {
  try {
    const loggedInUser = await callGraphQL<GetLoggedInUserQuery>(
      customLoggedInUser,
      {
        variables: {}
      }
    );

    return loggedInUser;
  } catch (error) {
    // location.reload()
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateUserAPI = async (user) => {
  try {
    const updatedUser = await callGraphQL<UpdatePractitionerMutation>(
      updatePractitioner,
      {
        variables: {
          input: user
        }
      }
    );

    return updatedUser;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createPractitionerAPI = async (practitioner) => {
  try {
    const createdPractitioner = await callGraphQL<CreatePractitionerMutation>(
      createPractitioner,
      {
        variables: {
          input: practitioner
        }
      }
    );

    return createdPractitioner;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deactivateUserAPI = async (userId) => {
  try {
    const deactivatedUser = await callGraphQL<DeactivateUserMutation>(
      deactivateUser,
      {
        variables: {
          userId
        }
      }
    );

    return deactivatedUser;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const adminUploadUserProfilePicAPI = async ({
  fileName,
  keyPrefix,
  contentType,
  metadata,
  onBehalfOf
}) => {
  try {
    const adminUserProfilePic =
      await callGraphQL<AdminCreatePublicDocumentUploadMutation>(
        adminCreatePublicDocumentUpload,
        {
          variables: {
            fileName: fileName,
            documentType: 'ProfilePicture',
            keyPrefix: keyPrefix,
            // Use to create a folder structure in S3
            contentType: contentType,
            // MIME type, e.g. application/pdf, image/jpeg
            metadata: metadata,
            onBehalfOf: onBehalfOf
          }
        }
      );

    return adminUserProfilePic;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const selfUploadUserProfilePicAPI = async ({
  fileName,
  keyPrefix,
  contentType,
  metadata
}) => {
  try {
    const selfUserProfilePic =
      await callGraphQL<CreatePublicDocumentUploadMutation>(
        createPublicDocumentUpload,
        {
          variables: {
            fileName: fileName,
            documentType: 'ProfilePicture',
            keyPrefix: keyPrefix,
            // Use to create a folder structure in S3
            contentType: contentType,
            // MIME type, e.g. application/pdf, image/jpeg
            metadata: metadata
          }
        }
      );

    return selfUserProfilePic;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
